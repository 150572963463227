import { FC, useState } from 'react';
import { Tag } from '../../../../../lib/model/Tag';
import { TagTypeWithColor } from './Tags/tag_type';
import { Button, Checkbox } from 'antd';

type TagWithColor = {
	id: string;
	title: string;
	color: string;
};

interface IProps {
	items: Tag[];
	insert_tag_with_color: (item: TagWithColor) => void;
	selected_tags: Tag[];
	tag_type: TagTypeWithColor;
	get_tag_type_title: (tag: Tag) => string;
}

export const ListTagsBooksSeries: FC<IProps> = ({
	insert_tag_with_color,
	items,
	selected_tags,
	tag_type,
	get_tag_type_title,
}) => {
	const [checked, setChecked] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const toggleChecked = () => {
		setChecked(!checked);
	};
	const toggleDisable = () => {
		setDisabled(!disabled);
	};

	return (
		<table className='table-layout: auto;'>
			{items
				.filter((tag) => {
					if (!selected_tags.some((t) => t.id === tag.id)) {
						return tag;
					}

					return null;
				})
				.map((tag) => {
					return (
						<tr data-row-key='1' className='ant-table-row ant-table-row-level-0 '>
							<td>
								<Checkbox
									checked={checked}
									disabled={disabled}
									onChange={() => insert_tag_with_color({ ...tag, color: tag_type.color })}
								>
									{get_tag_type_title(tag)}
								</Checkbox>
							</td>
						</tr>
					);
				})}
		</table>
	);
};
