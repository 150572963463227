import { Card, Input, Radio } from 'antd';
import { FC } from 'react';
import { FormField } from '../../../../../lib/components/Form/FormField';
import { FormState } from '../../../../../lib/hooks/useForm/types';
import { ClientConfigFormValues } from '../../hooks/useClientConfigForm';
import { AddDomainInput } from './AddDomainInput';
import { DomainsList } from './DomainsList';

interface IProps {
	form_state: FormState<ClientConfigFormValues>;

	add_domain?: (domain: any) => void;
	remove_domain?: (domain: any) => void;
	change_field_value: (field: keyof ClientConfigFormValues, value: any) => void;
	is_updating: boolean;
}

export const BaseClientConfigForm: FC<IProps> = ({
	form_state,
	change_field_value,
	is_updating,
	remove_domain = () => {},
	add_domain = () => {},
}) => {
	return (
		<>
			<FormField
				{...{
					label: 'ID',
					name: 'id',
					form_state,
					required: true,

					children: is_updating ? (
						<span>{form_state.value.id}</span>
					) : (
						<Input
							{...{
								disabled: true,
								value: form_state.value.id,
								type: 'number',
								min: 1,
								onChange: (event) => change_field_value('id', event.target.value + ''),
							}}
						/>
					),
				}}
			/>

			<FormField
				{...{
					label: 'Sigla',
					name: 'initials',
					form_state,
					required: true,
					children: is_updating ? (
						<span>{form_state.value.initials}</span>
					) : (
						<Input
							{...{
								value: form_state.value.initials,
								disabled: is_updating,
								onChange: (event) => change_field_value('initials', event.target.value.toUpperCase()),
							}}
						/>
					),
				}}
			/>

			<FormField
				{...{
					label: 'CPF / CNPJ',
					name: 'c_code',
					form_state,
					required: true,
					children: (
						<Input
							{...{
								value: form_state.value.c_code,
								onChange: (event) => change_field_value('c_code', event.target.value),
							}}
						/>
					),
				}}
			/>

			<FormField
				{...{
					label: 'Nome',
					name: 'name',
					form_state,
					required: true,
					children: (
						<Input
							{...{
								value: form_state.value.name,
								onChange: (event) => change_field_value('name', event.target.value),
							}}
						/>
					),
				}}
			/>

			{is_updating && (
				<Card style={{ marginBottom: '2rem', marginTop: '2rem' }}>
					<DomainsList domains={form_state.value.domains} on_removed={remove_domain} />
					<AddDomainInput on_added={add_domain} />
				</Card>
			)}
		</>
	);
};
