import { FC, useEffect, useState } from 'react';
import { Tag } from '../../../../../../lib/model/Tag';
import { ContentSeriesModal } from './ContentSeriesModal';
import { SelectedTags } from './SelectedTags';
import { TagsFormField } from './TagsFormField';
import { TagTypeWithColor } from './tag_type';
import { request } from '../../../../../../lib/utils/request';
import { Content } from '../../../../../../lib/model/Content';

interface IProps {
	tags: Tag[];
	show_content_list: () => void;
	visible: boolean;
	close: () => void;
	on_change: (tags: Tag[]) => void;
	tag_type: TagTypeWithColor;
	on_select_tag_type: (tag_type: TagTypeWithColor) => void;
	class_name?: string;
	isBook: boolean;
	contents: Content[] | [];
}

const BASE_URL = process.env.REACT_APP_PERIODIC_API || '';

export const SelectContractTags: FC<IProps> = ({
	show_content_list,
	tags,
	tag_type,
	on_change,
	close,
	on_select_tag_type,
	visible,
	class_name = 'flex flex-col pt-5',
	isBook,
	contents,
}) => {
	const [periodics_list, set_periodics_list] = useState<any[]>([]);
	async function getPeriodics() {
		const result = await request().get(`${BASE_URL}/periodics`);
		set_periodics_list(result as any[]);
	}

	useEffect(() => {
		getPeriodics();
	}, []);

	return (
		<section className={class_name}>
			<TagsFormField
				{...{
					show_content_list,
				}}
			/>
			<SelectedTags
				{...{
					tags,
					periodics: periodics_list,
				}}
			/>
			<ContentSeriesModal
				{...{
					tag_type,
					visible,
					on_change,
					close,
					tags,
					on_select_tag_type,
					periodics: periodics_list,
					isBook,
					contents,
				}}
			/>
		</section>
	);
};
