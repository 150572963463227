import { FC, useState } from 'react';
import { SearchableProvider } from '../../../../lib/components/SearchableDataTable/SearchableProvider';
import { PageLayout } from '../../../../lib/shell/PageLayout/PageLayout';
import { ContractDatatableModal } from '../client_contracts/components/ContractDataTableModal';
import { CopyrightDatatable } from '../components/CopyrightDatatable';

const partner_init = {
	id: '',
	name: '',
};

export const CopyrightListPage: FC = () => {
	const [show_contracts, set_show_contracts] = useState(false);
	const [partner, set_client] = useState(partner_init);

	return (
		<SearchableProvider
			{...{
				entity_name: 'admin-plat-clients-config',
				default_query: {
					limit: 50,
					page: 1,
					sort: 'sort1',
					sort_dir: 'asc',
					tags: '',
					text: '',
				},
			}}
		>
			<PageLayout title='Direito Autoral'>
				<CopyrightDatatable
					{...{
						set_show_contracts_list: () => set_show_contracts(true),
						set_client,
					}}
				/>
			</PageLayout>
			<ContractDatatableModal
				{...{ visible: show_contracts, close: () => set_show_contracts(false), partner }}
			/>
		</SearchableProvider>
	);
};
