import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRequestFeedback } from '../../../../lib/hooks';
import { PageLayout } from '../../../../lib/shell/PageLayout/PageLayout';
import { AddDigitalConfigForm } from '../components/forms/AddDigitalConfigForm';
import { CreateClientConfigDto } from '../dtos/CreateClientConfigDto';
import { useDigitalConfigApi } from '../hooks/useDigitalConfigApi';
import { DigitalBreadcrumb } from '../components/DigitalBreadcrumb';
import { usePartnerConfigForm } from '../hooks/useClientConfigForm';

export const AddDigitalConfigPage: FC = () => {
	const { insert, get_last_id } = useDigitalConfigApi();
	const navigate = useNavigate();
	const { form_state, show_errors, change_field_value } = usePartnerConfigForm();

	function on_submit() {
		show_errors();

		if (!form_state.valid) {
			return;
		}

		const dto: CreateClientConfigDto = {
			c_code: form_state.value.c_code.trim(),
			id: form_state.value.id.trim(),
			initials: form_state.value.initials.trim(),
			name: form_state.value.name.trim(),
			unlimited: form_state.value.unlimited,
			partner: true,
			sale: form_state.value.sale,
			profile: 4,
			search_ai: form_state.value.search_ai,
		};

		insert.send(dto);
	}

	useRequestFeedback(insert.result, insert.error);

	useEffect(() => {
		if (insert.result) {
			navigate(`/plataforma/digital/${form_state.value.id}`, { replace: true });
		}
	}, [insert.result]);

	useEffect(() => {
		get_last_id.get();
	}, []);

	useEffect(() => {
		if (get_last_id.result) {
			const last_id = Number(String(get_last_id.result.last_id));
			if (last_id) {
				change_field_value('id', String(last_id + 1));
			}
		}
	}, [get_last_id.result]);

	return (
		<PageLayout {...{ title: 'Novo', breadcrumb: () => <DigitalBreadcrumb />, browser_back: true }}>
			<AddDigitalConfigForm {...{ change_field_value, form_state, loading: insert.loading, on_submit }} />
		</PageLayout>
	);
};
