import { Breadcrumb } from 'antd';
import { FC } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
	client_id?: string;
	contract_number?: string;
}

export const ContractsBreadcrumb: FC<IProps> = ({ client_id, contract_number }) => {
	return (
		<Breadcrumb>
			{client_id && (
				<>
					<Breadcrumb.Item key='clients'>
						<Link to={`/plataforma/digital`}>Digital</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item key='contracts_client'>
						<Link to={`/plataforma/digital/${client_id}`}>{client_id}</Link>
					</Breadcrumb.Item>
				</>
			)}
			{contract_number && (
				<>
					<Breadcrumb.Item key='contracts'>
						<Link to={`/plataforma/digital`}>Contratos</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item key={`${contract_number}`}>{contract_number}</Breadcrumb.Item>
				</>
			)}
		</Breadcrumb>
	);
};
