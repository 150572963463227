import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProtectedContent } from '../../../lib/features/auth/components/ProtectedContent';
import { Role } from '../../../lib/features/auth/model/Role';
import { AddContractPage } from './client_contracts/page/AddContractPage';
import { UpdateContractPage } from './client_contracts/page/UpdateContractPage';

import { AddDigitalConfigPage } from './pages/AddDigitalConfigPage';
import { UpdateDigitalConfigPage } from './pages/UpdateDigitalConfigPage';
import { DigitalListPage } from './pages/DigitalListPage';

export const PlatformDigitalRoutes: FC = () => (
	<ProtectedContent {...{ roles: [Role.Admin, Role.PlatformAdmin, Role.PlatformDigital] }}>
		<Routes>
			<Route {...{ path: ':id/contratos/novo', element: <AddContractPage /> }} />
			<Route {...{ path: ':id/contratos/:c_number', element: <UpdateContractPage /> }} />
			<Route {...{ path: 'novo', element: <AddDigitalConfigPage /> }} />
			<Route {...{ path: ':id', element: <UpdateDigitalConfigPage /> }} />
			<Route {...{ path: '/', element: <DigitalListPage /> }} />
		</Routes>
	</ProtectedContent>
);
