import { CheckCircleTwoTone } from '@ant-design/icons';
import { Button } from 'antd';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddButton } from '../../../../lib/components/Buttons/AddButton';
import { CellStyle } from '../../../../lib/components/DataTable/CellStyle';
import { ColumnFactory } from '../../../../lib/components/DataTable/ColumnFactory';
import { Client } from '../../../../lib/model/Client';
import { SearchableDataTableForClient } from '../../../../lib/components/SearchableDataTable/SearchableDataTableForClient';

interface IProps {
	set_show_contracts_list: () => void;
	set_client: (client: Client) => void;
}

export const ClientsDatatable: FC<IProps> = ({ set_show_contracts_list, set_client }) => {
	const navigate = useNavigate();

	return (
		<SearchableDataTableForClient
			{...{
				row_height: () => 60,
				sortable_fields: ['Id', 'Nome', 'Sigla'],
				searchable_defaults: {
					sort_field: 'sort1',
					sort_dir: 'asc',
				},

				buttons_on_top: [<AddButton onClick={() => navigate(`novo`)} />],

				columns: [
					{
						col_def: {
							field: 'unlimited',
							headerName: 'Ilimitado',
							width: 120,
							cellStyle: CellStyle.Centered,
						},
						renderer: (props: { data: { unlimited: boolean } }) =>
							props.data.unlimited ? (
								<CheckCircleTwoTone
									{...{
										size: 32,
										twoToneColor: '#52c41a',
									}}
								/>
							) : null,
					},

					ColumnFactory.Link(
						{
							field: 'id',
							headerName: 'ID',
							resizable: false,
							rowDrag: false,
							width: 120,
						},
						(props: { data: { id: string } }) => props.data.id
					),

					{
						field: 'initials',
						headerName: 'Sigla',
						resizable: false,
						rowDrag: false,
						width: 120,
					},

					{
						field: 'name',
						headerName: 'Nome',
						resizable: true,
						rowDrag: false,
						width: 300,
					},
					{
						col_def: {
							field: 'buttons',
							headerName: '',
							width: 150,
							cellStyle: CellStyle.Centered,
							pinned: 'right',
						},
						renderer: (props: { data: Client }) => (
							<Button
								onClick={() => {
									set_show_contracts_list();
									set_client(props.data);
								}}
							>
								Ver Contratos
							</Button>
						),
					},
				],
			}}
		/>
	);
};
