import { Breadcrumb } from 'antd';
import { FC } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
	client_id?: string;
}

export const CopyrightBreadcrumb: FC<IProps> = ({ client_id }) => (
	<Breadcrumb>
		<Breadcrumb.Item key='clients'>
			<Link to={'/plataforma/direitoautoral'}>Parceiros</Link>
		</Breadcrumb.Item>

		{client_id ? (
			<Breadcrumb.Item key='client_name'>{client_id}</Breadcrumb.Item>
		) : (
			<Breadcrumb.Item key='new_client'>Novo Parceiro</Breadcrumb.Item>
		)}
	</Breadcrumb>
);
