import { FC, useEffect, useState } from 'react';
import { CloseButton } from '../../../../../lib/components/Buttons/CloseButton';
import { Tag } from '../../../../../lib/model/Tag';
import { request } from '../../../../../lib/utils/request';
import { SelectedTags } from './SelectedTags';
import { TagTypeWithColor } from './Tags/tag_type';
import { Card, Input, Select, message } from 'antd';
import { AddButton } from '../../../../../lib/components/Buttons/AddButton';
import { BookEditionSelect } from '../../../../../lib/features/book_edition/components/BookEditionSelect';
import { Content } from '../../../../../lib/model/Content';
import { BookEditionSelectForPartner } from '../../../../../lib/features/book_edition/components/BookEditionSelectForPartner';

const BASE_URL = process.env.REACT_APP_PERIODIC_API || '';
const Option = Select;

interface IProps {
	selected_contents: Tag[];
	tag_type: TagTypeWithColor;
	on_change: (tags: Tag[]) => void;
	close: () => void;
	contents: Content[] | [];
}

export const LooseBookForm: FC<IProps> = ({ on_change, tag_type, selected_contents, close, contents }) => {
	const [editions, set_edition_selecteds] = useState<Content[]>(contents);
	const arrayTags: Tag[] = [];
	const arrayContent: Content[] = [];
	for (const item of selected_contents) {
		const selecionados: Content = {
			id: item.id,
			title: item.title,
			authors: '',
		};
		arrayContent.push(selecionados);
	}

	useEffect(() => {
		insert_tag_with_color();
	}, [editions]);

	const [selected_tags, set_selected_tags] = useState(selected_contents);
	const [is_loading, set_is_loading] = useState(false);
	const [periodics_list, set_periodics_list] = useState<any[]>([]);

	function insert_tag_with_color() {
		for (const item of editions) {
			const tag: Tag = {
				id: item.id,
				title: item.title,
			};
			const a = 0;
			const exists = selected_tags.filter((item) => item.id === tag.id);
			if (exists.length == 0) {
				arrayTags.push(tag);
			}
		}
		set_selected_tags([...selected_tags, ...arrayTags]);
	}

	function remove_tag(tag: Tag) {
		const new_contents = selected_tags.filter((content) => content !== tag);
		set_selected_tags(new_contents);
	}

	function select_tags() {
		on_change(selected_tags);
		close();
	}

	const get_tag_type_title = (tag: Tag): string => {
		if (tag?.id?.includes('book_')) {
			return `Livro Avulso: ${tag.title}`;
		}
		if (tag?.id?.includes('book')) {
			return tag?.id?.includes('legislacao-comentada') ? `${tag.title}` : `Livros: ${tag.title}`;
		}
		if (tag?.id?.includes('video')) {
			return `Vídeos: ${tag.title}`;
		}
		if (tag?.id?.includes('periodic')) {
			let title = tag?.title || '';
			const tagSplited = String(tag?.id).split('-');
			const idSplited = String(tagSplited?.[1] || '').split('_');
			const periodicId = idSplited?.[0] || '';
			const start_number = idSplited?.[2] || '';
			const final_number = tagSplited?.[2] || '';
			const periodicsArray = periodics_list || [];
			if (periodicId && start_number && final_number && periodicsArray?.length) {
				const p = periodicsArray.find((v) => String(v.numId).replace('P', '') === periodicId);
				title = p?.acronym ? `${p?.acronym} - De ${start_number} a ${final_number}` : title;
			}

			return `Revistas: ${title}`;
		}
		return `${tag.title}`;
	};

	async function getPeriodics() {
		set_is_loading(true);
		const result = await request().get(`${BASE_URL}/periodics`);
		set_periodics_list(result as any[]);
		set_is_loading(false);
	}

	useEffect(() => {
		getPeriodics();
	}, []);

	useEffect(() => {
		on_change(selected_tags);
	}, [selected_tags]);

	return (
		<section className='flex flex-col w-full'>
			<div className='flex flex-row justify-between'>
				<div className='grid grid-cols-2'>
					<BookEditionSelectForPartner {...{ value: arrayContent, on_change: set_edition_selecteds }} />
				</div>
			</div>

			{selected_tags.length !== 0 && !is_loading && (
				<SelectedTags {...{ items: selected_tags, remove_tag, get_tag_type_title }} />
			)}
			<div className='flex justify-end mt-8'>
				<CloseButton disabled={selected_tags.length === 0} onClick={select_tags} />
			</div>
		</section>
	);
};
