import { useForm } from '../../../../lib/hooks/useForm/useForm';
import { form_validators } from '../../../../lib/hooks/useForm/validators';

export type ClientConfigFormValues = {
	c_code: string;
	id: string;
	name: string;
	initials: string;
	unlimited: boolean;
	max_users: number;
	max_simultaneous_users: number;
	ips: string[];
	domains: string[];
	ip_ranges: string[];
	contract_managers: string[];
	sso_id: string;
	sso_login_url: string;
	sso_token_issuer: string;
	partner: boolean;
	sale: boolean;
	profile: number;
	search_ai: boolean;
};

export function usePartnerConfigForm() {
	const form = useForm<ClientConfigFormValues>(
		{
			c_code: '',
			id: '',
			name: '',
			initials: '',
			unlimited: false,
			max_users: 3,
			max_simultaneous_users: 99,
			ips: [],
			domains: [],
			ip_ranges: [],
			contract_managers: [],
			sso_id: '',
			sso_login_url: '',
			sso_token_issuer: '',
			partner: true,
			sale: true,
			profile: 1,
			search_ai: false,
		},
		function validate_form(state) {
			return {
				id: form_validators.required_text_field(state.value.id),
				name: form_validators.required_text_field(state.value.name),
				initials: form_validators.required_text_field(state.value.initials),
				unlimited: '',
				max_users: state.value.unlimited ? '' : +state.value.max_users > 0 ? '' : 'Valor inválido',
				max_simultaneous_users: state.value.unlimited
					? ''
					: +state.value.max_simultaneous_users > 0
					? ''
					: 'Valor inválido',
				ips: '',
				domains: '',
				contract_managers: '',
				ip_ranges: '',
				c_code: form_validators.required_text_field(state.value.c_code),
				sso_id: '',
				sso_login_url: '',
				sso_token_issuer: '',
				partner: '',
				sale: '',
				profile: state.value.profile > 0 ? '' : 'Valor inválido',
				search_ai: '',
			};
		}
	);

	return {
		...form,
		add_into: (field_name: keyof ClientConfigFormValues) => (item: string) => {
			const field_value = form.form_state.value[field_name] as string[];
			form.change_field_value(field_name, [...field_value, item]);
		},
		remove_from: (field_name: keyof ClientConfigFormValues) => (item: string) => {
			const field_value = form.form_state.value[field_name] as string[];
			form.change_field_value(
				field_name,
				field_value.filter((i) => i !== item)
			);
		},
		validators: {
			valid_ip,
			email: (email: string): boolean => {
				if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
					return false;
				}

				return true;
			},
			domain: (domain: string): boolean => {
				const BLOCKED_DOMAINS = ['gmail', 'hotmail'];

				if (!domain) {
					return false;
				}

				const d = domain.trim().toLowerCase();

				if (!d.includes('.')) {
					return false;
				}

				if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(`test@${d}`)) {
					return false;
				}

				if (BLOCKED_DOMAINS.some((blocked) => d.includes(blocked))) {
					return false;
				}

				return true;
			},

			ip: valid_ip,
			range: (range: string): boolean => {
				if (!range) {
					return false;
				}

				const r = range;

				if (!r.includes('/')) {
					return false;
				}

				const [ip, mask] = r.split('/');

				if (+mask > 32) {
					return false;
				}

				if (+mask < 16) {
					return false;
				}

				return valid_ip(ip);
			},
		},
	};
}

function valid_ip(ip: string): boolean {
	if (!ip) {
		return false;
	}

	const reg_ex =
		/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

	return reg_ex.test(ip);
}
