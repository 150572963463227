import { Radio } from 'antd';
import { FC } from 'react';
import { FormButtons } from '../../../../../lib/components/Form/FormButtons';
import { FormContainer } from '../../../../../lib/components/Form/FormContainer';
import { FormField } from '../../../../../lib/components/Form/FormField';
import { FormState } from '../../../../../lib/hooks/useForm/types';
import { ClientConfigFormValues } from '../../hooks/useClientConfigForm';
import { BasePartnerConfigForm } from './BasePartnerConfigForm';

interface IProps {
	form_state: FormState<ClientConfigFormValues>;
	change_field_value: (field: keyof ClientConfigFormValues, value: any) => void;
	loading: boolean;
	on_submit: () => void;
}

export const AddPartnerConfigForm: FC<IProps> = ({ form_state, change_field_value, loading, on_submit }) => {
	return (
		<FormContainer {...{ disabled: false }}>
			<BasePartnerConfigForm
				{...{
					is_updating: false,
					form_state,
					change_field_value,
				}}
			/>

			<FormField
				{...{
					label: 'Busca por IA',
					name: 'search_ai',
					form_state,
					required: true,
					children: (
						<Radio.Group
							{...{
								options: [
									{ label: 'Sim', value: true },
									{ label: 'Não', value: false },
								],
								onChange: () => change_field_value('search_ai', !form_state.value.search_ai),
								value: form_state.value.search_ai,
								optionType: 'button',
								buttonStyle: 'solid',
							}}
						/>
					),
				}}
			/>
			<FormButtons
				{...{
					disabled: loading || !form_state.dirty,
					go_back_on_cancel: true,
					on_add_button_click: on_submit,
					on_update_button_click: undefined,
				}}
			/>
		</FormContainer>
	);
};
