import { Modal } from 'antd';
import { FC } from 'react';
import { Tag } from '../../../../../../lib/model/Tag';
import { SelectTags } from '../SelectTags';
import { SelectTagType } from './SelectTagType';
import { TagTypeWithColor } from './tag_type';
import { PeriodicTagsForm } from '../PeriodicTagsForm';
import { NewsTagForm } from '../NewsTagForm';
import { CodeTagForm } from '../CodeTagForm';
import { SelectTagsVidsSeries } from '../SelectTagsVidsSeries';
import { SelectTagsBooksSeries } from '../SelectTagsBooksSeries';

interface IProps {
	visible: boolean;
	close: () => void;
	on_change: (tags: Tag[]) => void;
	tag_type: TagTypeWithColor;
	on_select_tag_type: (tag_type: TagTypeWithColor) => void;
	tags: Tag[];
	periodics?: any[];
}

export const ContentSeriesModal: FC<IProps> = ({
	visible,
	close,
	on_change,
	tag_type,
	on_select_tag_type,
	tags,
	periodics,
}) => {
	return (
		<Modal
			{...{
				visible,
				closable: true,
				onCancel: close,
				centered: true,
				destroyOnClose: true,
				title: 'Seleção de Conteúdos',
				width: 800,
				footer: null,
			}}
		>
			<SelectTagType {...{ on_select_tag_type }} />
			{tag_type.value && (
				<>
					<h2 className='mt-2 mb-0'>
						{tag_type?.value === 'periodic' ? 'Tags de Revistas' : ''}
						{!['periodic', 'news', 'code'].includes(tag_type?.value)
							? `Séries de ${tag_type.title}`
							: ''}
					</h2>
					{tag_type?.value === 'periodic' ? (
						<PeriodicTagsForm
							{...{
								selected_contents: tags,
								tag_type,
								on_change,
								close,
							}}
						/>
					) : (
						<></>
					)}
					{tag_type?.value === 'news' ? (
						<NewsTagForm
							{...{
								selected_contents: tags,
								tag_type,
								on_change,
								close,
							}}
						/>
					) : (
						<></>
					)}
					{tag_type?.value === 'code' ? (
						<CodeTagForm
							{...{
								selected_contents: tags,
								tag_type,
								on_change,
								close,
							}}
						/>
					) : (
						<></>
					)}
					{tag_type?.value === 'book' ? (
						<SelectTagsBooksSeries
							{...{
								selected_contents: tags,
								tag_type,
								on_change,
								close,
								periodics,
							}}
						/>
					) : (
						<></>
					)}
					{tag_type?.value === 'video' ? (
						<SelectTagsVidsSeries
							{...{
								selected_contents: tags,
								tag_type,
								on_change,
								close,
								periodics,
							}}
						/>
					) : (
						<></>
					)}

					{!['periodic', 'news', 'code', 'book', 'video'].includes(tag_type?.value) ? (
						<SelectTags
							{...{
								selected_contents: tags,
								tag_type,
								on_change,
								close,
								periodics,
							}}
						/>
					) : (
						<></>
					)}
				</>
			)}
		</Modal>
	);
};
