import { FC, useEffect, useState } from 'react';
import { CloseButton } from '../../../../../lib/components/Buttons/CloseButton';
import { Tag } from '../../../../../lib/model/Tag';
import { request } from '../../../../../lib/utils/request';
import { SelectedTags } from './SelectedTags';
import { TagTypeWithColor } from './Tags/tag_type';
import { Card, Switch, message } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const BASE_URL = process.env.REACT_APP_PERIODIC_API || '';

interface IProps {
	selected_contents: Tag[];
	tag_type: TagTypeWithColor;
	on_change: (tags: Tag[]) => void;
	close: () => void;
}

export const PlatformAllTagForm: FC<IProps> = ({ on_change, tag_type, selected_contents, close }) => {
	const [selected_tags, set_selected_tags] = useState(selected_contents);
	const [is_loading, set_is_loading] = useState(false);
	const [has_news, set_has_news] = useState(false);
	const [periodics_list, set_periodics_list] = useState<any[]>([]);

	function insert_tag_with_color() {
		const tag: Tag = {
			id: `platform-all`,
			title: `Plataforma Fórum completa`,
		};
		set_selected_tags([...selected_tags, tag]);
		message.success('Adicionado com sucesso.');
	}

	function remove_tag() {
		const new_contents = selected_tags.filter((content) => String(content.id) !== 'platform-all');
		set_selected_tags(new_contents);
		message.success('Removido com sucesso.');
	}

	async function getPeriodics() {
		set_is_loading(true);
		const result = await request().get(`${BASE_URL}/periodics`);
		set_periodics_list(result as any[]);
		set_is_loading(false);
	}

	useEffect(() => {
		getPeriodics();
	}, []);

	useEffect(() => {
		on_change(selected_tags);
		const news = !!selected_tags.find((content) => String(content.id).includes('platform-all'));
		set_has_news(news);
	}, [selected_tags]);

	return (
		<section className='flex flex-col w-full'>
			<div className='flex flex-row justify-between'>
				<Card
					style={{ background: '#fff', marginTop: 1, width: '100%' }}
					bodyStyle={{ width: '100%' }}
					className='flex flex-row rounded border border-gray-100 p-4 bg-gray-50 flex-wrap max-w-lg'
				>
					<div className='grid grid-cols-2'>
						<div className='col-span-2 block mb-3'>
							<label>
								<span className='text-xs mr-1'>Deseja liberar a plataforma completa?</span>
							</label>
							<br />
							<Switch
								checkedChildren={<CheckOutlined />}
								unCheckedChildren={<CloseOutlined />}
								checked={has_news}
								onChange={has_news ? remove_tag : insert_tag_with_color}
							/>
						</div>
					</div>
				</Card>
			</div>
		</section>
	);
};
