export enum Role {
	// TODO: CLASSIFICAR TODAS AS ROLES DISPONÍVEIS
	Admin = 'admin',
	PlatformAdmin = 'platform:admin',
	PlatformClipping = 'platform:clippings',
	PlatformContracts = 'platform:contracts',
	PlatformClients = 'platform:clients',
	PlatformPartners = 'platform:partners',
	PlatformDigital = 'platform:digital',
	PlatformCopyfight = 'platform:copyright',
	StoreContentAssignment = 'store:content-assignment',
	StoreAdmin = 'store:admin',
	StorePricing = 'store:pricing',
	StoreBanners = 'store:banners',
	StoreSections = 'store:sections',
	StoreUsers = 'store:users',
	ContentTags = 'tags',
	Contents = 'contents',
	ContentGroup = 'content-group',
}
