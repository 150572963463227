import { FC } from 'react';
import { Tag } from '../../../../../lib/model/Tag';
import { TagTypeWithColor } from './Tags/tag_type';
import { Button, Checkbox } from 'antd';

type TagWithColor = {
	id: string;
	title: string;
	color: string;
};

interface IProps {
	items: Tag[];
	insert_tag_with_color: (item: TagWithColor) => void;
	selected_tags: Tag[];
	tag_type: TagTypeWithColor;
	get_tag_type_title: (tag: Tag) => string;
}

export const ListTags: FC<IProps> = ({
	insert_tag_with_color,
	items,
	selected_tags,
	tag_type,
	get_tag_type_title,
}) => {
	// return (
	// 	<ul className='flex flex-row rounded border border-gray-100 p-4 bg-gray-50 flex-wrap max-w-lg'>
	// 		{items
	// 			.filter((tag) => {
	// 				if (!selected_tags.some((t) => t.id === tag.id)) {
	// 					return tag;
	// 				}

	// 				return null;
	// 			})
	// 			.map((tag) => {
	// 				return (
	// 					<li
	// 						className={`h-7 mx-0.5 py-1 px-2 mb-3 rounded text-white cursor-pointer ${tag_type.color} hover:bg-opacity-70`}
	// 						onClick={() => insert_tag_with_color({ ...tag, color: tag_type.color })}
	// 					>
	// 						{get_tag_type_title(tag) + }
	// 					</li>
	// 				);
	// 			})}
	// 	</ul>
	// );

	return (
		<table className='table-layout: auto;'>
			{items
				.filter((tag) => {
					if (!selected_tags.some((t) => t.id === tag.id)) {
						return tag;
					}

					return null;
				})
				.map((tag) => {
					return (
						<tr data-row-key='1' className='ant-table-row ant-table-row-level-0 '>
							<td className={`mx-0.5 py-1 px-2 mb-2 rounded text-black`}>{get_tag_type_title(tag)}</td>
							<td>
								<Button
									type='link'
									onClick={() => insert_tag_with_color({ ...tag, color: tag_type.color })}
								>
									Liberar
								</Button>
							</td>
						</tr>

						// <li
						// 	className={`h-7 mx-0.5 py-1 px-2 mb-3 rounded text-white cursor-pointer  hover:bg-opacity-70`}
						// 	onClick={() => insert_tag_with_color({ ...tag, color: tag_type.color })}
						// >
					);
				})}
		</table>
	);
};
