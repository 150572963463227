import { Tabs } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../lib/components/Loader';
import { DownloadConfig } from '../../../../lib/model/Client';
import { PageLayout } from '../../../../lib/shell/PageLayout/PageLayout';
import { request } from '../../../../lib/utils/request';
import { PartnersBreadcrumb } from '../components/PartnersBreadcrumb';
import { ContractDatatable } from '../components/ContractDataTable';
import { ContractedContentsModal } from '../components/ContractedContentsModal';
import { DownloadsConfigForm } from '../components/forms/DownloadsConfigForm';
import { UpdatePartnerConfigForm } from '../components/forms/UpdatePartnerConfigForm';
import { DownloadConfigDto } from '../dtos/DownloadConfigDto';
import { UpdateClientConfigDto } from '../dtos/UpdateClientConfigDto';
import { usePartnerConfigApi } from '../hooks/usePartnerConfigApi';
import { usePartnerConfigForm } from '../hooks/useClientConfigForm';

const { TabPane } = Tabs;

export const UpdatePartnerConfigPage: FC = () => {
	const { id } = useParams();
	const { partner, update, loading } = usePartnerConfigApi(id);
	const [show_contracted_contents, set_show_contracted_contents] = useState(false);

	const { form_state, show_errors, change_field_value, fill_with_data, remove_from, add_into } =
		usePartnerConfigForm();

	useEffect(() => {
		if (partner) {
			fill_with_data(partner);
		}
	}, [partner?.refresh_date]);

	function on_submit() {
		show_errors();

		if (!form_state.valid) {
			return;
		}

		const dto: UpdateClientConfigDto = {
			c_code: form_state.value.c_code.trim(),
			initials: form_state.value.initials.trim(),
			name: form_state.value.name.trim(),
			unlimited: form_state.value.unlimited,
			contract_managers: form_state.value.contract_managers,
			domains: form_state.value.domains,
			ip_ranges: form_state.value.ip_ranges,
			ips: form_state.value.ips,
			max_simultaneous_users: form_state.value.max_simultaneous_users,
			max_users: form_state.value.max_users,
			sso_login_url: form_state.value.sso_login_url,
			sso_token_issuer: form_state.value.sso_token_issuer,
			partner: true,
			sale: form_state.value.sale,
			profile: 2,
			search_ai: form_state.value.search_ai,
		};
		update.send(dto);
	}

	return (
		<PageLayout
			{...{
				title: partner?.name || '',
				browser_back: true,
				breadcrumb: () => <PartnersBreadcrumb {...{ client_id: partner?.id }} />,
			}}
		>
			{partner && (
				<Tabs type='card'>
					<TabPane tab='Configurações de Parceiro' key={partner.id}>
						<UpdatePartnerConfigForm
							{...{
								loading: update.loading || loading,
								on_submit,
								add_into,
								change_field_value,
								form_state,
								remove_from,
							}}
						/>
					</TabPane>

					<TabPane tab='Liberações' key='contracts'>
						<ContractDatatable
							{...{ partner, show_contracted_contents: () => set_show_contracted_contents(true) }}
						/>
						<ContractedContentsModal
							{...{
								partner,
								close: () => set_show_contracted_contents(false),
								visible: show_contracted_contents,
							}}
						/>
					</TabPane>
					<TabPane tab='Configuracao de Downloads' key='downloads'>
						<Loader
							{...{
								request: () =>
									request().get<DownloadConfigDto>(
										`${process.env.REACT_APP_CONTENT_DOWNLOADS_API}/${partner.id}`
									),
								renderChildren: (download_config: DownloadConfig) => (
									<DownloadsConfigForm {...{ download_config }} />
								),
							}}
						/>
					</TabPane>
				</Tabs>
			)}
		</PageLayout>
	);
};
