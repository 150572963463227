import { FC, useEffect, useState } from 'react';
import { CloseButton } from '../../../../../lib/components/Buttons/CloseButton';
import { Tag } from '../../../../../lib/model/Tag';
import { request } from '../../../../../lib/utils/request';
import { SelectedTags } from './SelectedTags';
import { TagTypeWithColor } from './Tags/tag_type';
import { Card, Switch, message } from 'antd';

const BASE_URL = process.env.REACT_APP_PERIODIC_API || '';

interface IProps {
	selected_contents: Tag[];
	tag_type: TagTypeWithColor;
	on_change: (tags: Tag[]) => void;
	close: () => void;
}

export const CodeTagForm: FC<IProps> = ({ on_change, tag_type, selected_contents, close }) => {
	const [selected_tags, set_selected_tags] = useState(selected_contents);
	const [is_loading, set_is_loading] = useState(false);
	const [has_code, set_has_code] = useState(false);
	const [periodics_list, set_periodics_list] = useState<any[]>([]);

	function insert_tag_with_color() {
		const tag: Tag = {
			id: `code`,
			title: `Códigos`,
		};
		set_selected_tags([...selected_tags, tag]);
		message.success('Adicionado com sucesso.');
	}

	function remove_tag() {
		const new_contents = selected_tags.filter((content) => String(content.id) !== 'code');
		set_selected_tags(new_contents);
		message.success('Removido com sucesso.');
	}

	function select_tags() {
		on_change(selected_tags);
		close();
	}

	const get_tag_type_title = (tag: Tag): string => {
		if (tag?.id?.includes('book_')) {
			return `Livro Avulso: ${tag.title}`;
		}
		if (tag?.id?.includes('book')) {
			return tag?.id?.includes('legislacao-comentada') ? `${tag.title}` : `Livros: ${tag.title}`;
		}
		if (tag?.id?.includes('video')) {
			return `Vídeos: ${tag.title}`;
		}
		if (tag?.id?.includes('periodic')) {
			let title = tag?.title || '';
			const tagSplited = String(tag?.id).split('-');
			const idSplited = String(tagSplited?.[1] || '').split('_');
			const periodicId = idSplited?.[0] || '';
			const start_number = idSplited?.[2] || '';
			const final_number = tagSplited?.[2] || '';
			const periodicsArray = periodics_list || [];
			if (periodicId && start_number && final_number && periodicsArray?.length) {
				const p = periodicsArray.find((v) => String(v.numId).replace('P', '') === periodicId);
				title = p?.acronym ? `${p?.acronym} - De ${start_number} a ${final_number}` : title;
			}

			return `Revistas: ${title}`;
		}
		return `${tag.title}`;
	};

	async function getPeriodics() {
		set_is_loading(true);
		const result = await request().get(`${BASE_URL}/periodics`);
		set_periodics_list(result as any[]);
		set_is_loading(false);
	}

	useEffect(() => {
		getPeriodics();
	}, []);

	useEffect(() => {
		on_change(selected_tags);
		const code = !!selected_tags.find((content) => String(content.id).includes('code'));
		set_has_code(code);
	}, [selected_tags]);

	return (
		<section className='flex flex-col w-full'>
			<div className='flex flex-row justify-between'>
				<Card
					style={{ background: '#fff', marginTop: 1, width: '100%' }}
					bodyStyle={{ width: '100%' }}
					className='flex flex-row rounded border border-gray-100 p-4 bg-gray-50 flex-wrap max-w-lg'
				>
					<div className='grid grid-cols-2'>
						<div className='col-span-2 block mb-3'>
							<label>
								<span className='text-xs mr-1'>Adicionar Códigos</span>
							</label>
							<br />
							<Switch checked={has_code} onChange={has_code ? remove_tag : insert_tag_with_color} />
						</div>
					</div>
				</Card>
			</div>

			{selected_tags.length !== 0 && !is_loading && (
				<SelectedTags {...{ items: selected_tags, remove_tag, get_tag_type_title }} />
			)}
			<div className='flex justify-end mt-8'>
				<CloseButton disabled={selected_tags.length === 0} onClick={select_tags} />
			</div>
		</section>
	);
};
