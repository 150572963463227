import { Tabs } from 'antd';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../lib/components/Loader';
import { ContentList } from '../../../../lib/model/ContentList';
import { PageLayout } from '../../../../lib/shell/PageLayout/PageLayout';
import { request } from '../../../../lib/utils/request';
import { AssociationDataTable } from '../components/AssociationDataTable';
import { AssociationInsertModal } from '../components/AssociationInsertModal';
import { ContentAssignmentBreadcrumb } from '../components/ContentAssignmentBreadcrumb';
import { ContentDataTable } from '../components/ContentDataTable';
import { ContentInsertModal } from '../components/ContentModal';
import { UpdateListName } from '../components/UpdateListName';
import { ContentListDto } from '../dtos/ContentListDto';

const BASE_URL = process.env.REACT_APP_CONTENT_ASSIGNMENT_API;

const { TabPane } = Tabs;

export const ContentPage: FC = () => {
	const { list_id } = useParams();

	const [visible_content_modal, set_visible_content_modal] = useState<boolean>(false);
	const [visible_email_form, set_visible_email_form] = useState<boolean>(false);

	const [contents, set_contents] = useState();
	const [associateds, set_associated] = useState();
	const [list_name, set_list_name] = useState('');

	return (
		<PageLayout
			{...{
				title: `Atualizar Lista - ${list_name}`,
				key: 'content-release',
				breadcrumb: () => <ContentAssignmentBreadcrumb {...{ list_name }} />,
				browser_back: true,
			}}
		>
			<Loader
				{...{
					reload_when_changes: [contents, associateds],
					request: () => request().get<ContentListDto>(`${BASE_URL}/${list_id}`),
					renderChildren: (content_list: ContentList) => {
						set_list_name(content_list.name);

						return (
							<Tabs type='card'>
								<TabPane tab='Atualizar Nome' key='list_name'>
									<div className='bg-gray-50 border border-gray-300 py-8 px-4 rounded-sm items-center'>
										<UpdateListName {...{ list_name: content_list.name }} />
									</div>
								</TabPane>

								<TabPane tab='Conteúdos' key='contents'>
									<ContentDataTable
										{...{
											set_visible_content_modal: () => set_visible_content_modal(true),
											contents: content_list.contents,
										}}
									/>
									<ContentInsertModal
										{...{
											visible: visible_content_modal,
											close: () => set_visible_content_modal(false),
											contents: content_list.contents,
											on_success: (contents: any) => set_contents(contents),
										}}
									/>
								</TabPane>

								<TabPane tab='Associados' key='associateds'>
									<AssociationDataTable
										{...{
											disabled: content_list.contents.length === 0,
											associateds: content_list.associateds,
											on_success: (value: any) => set_associated(value),
											set_visible_email_form: () => set_visible_email_form(true),
										}}
									/>
									<AssociationInsertModal
										{...{
											on_insert: (emails_list: any) => set_associated(emails_list),
											close_modal: () => set_visible_email_form(false),
											visible: visible_email_form,
										}}
									/>
								</TabPane>
							</Tabs>
						);
					},
				}}
			/>
		</PageLayout>
	);
};
