import { FC } from 'react';
import { Tag } from '../../../../../../lib/model/Tag';
import { verify_tag_type } from './verify_tag_type';
import { BookOutlined } from '@ant-design/icons';

interface IProps {
	tags: Tag[];
	periodics?: any[];
}

export const SelectedTags: FC<IProps> = ({ tags, periodics }) => {
	const tags_with_color = verify_tag_type(tags, periodics);
	return (
		<table className='table-layout: auto;'>
			{tags_with_color.map((tag) => {
				return (
					<tr data-row-key='1' className='ant-table-row ant-table-row-level-0 '>
						<td className={`mx-0.5 py-1 px-2 mb-2 rounded text-black`}>{tag.name}</td>
					</tr>
				);
			})}
		</table>
	);
};
