import { Tag } from '../../../../../../lib/model/Tag';

export function verify_tag_type(tags: Tag[], periodics: any[] = []) {
	const tags_with_color = tags.map((tag) => {
		if (tag.id.includes('book_')) {
			return {
				...tag,
				color: 'bg-green-700',
				name: tag?.id?.includes('legislacao-comentada') ? `${tag.title}` : `Livro Avulso: ${tag.title}`,
				type: 'book',
				value: 'Livros',
			};
		}
		if (tag.id.includes('book')) {
			return {
				...tag,
				color: 'bg-green-700',
				name: tag?.id?.includes('legislacao-comentada') ? `${tag.title}` : `Livros: ${tag.title}`,
				type: 'book',
				value: 'Livros',
			};
		}
		if (tag.id.includes('video')) {
			return {
				...tag,
				color: 'bg-blue-600',
				name: `Vídeos: ${tag.title}`,
				type: 'video',
				value: 'Vídeos',
			};
		}
		if (tag.id.includes('news')) {
			return {
				...tag,
				color: 'bg-yellow-500',
				name: `${tag.title}`,
				type: 'news',
				value: 'Informativos',
			};
		}
		if (tag.id.includes('code')) {
			return {
				...tag,
				color: 'bg-indigo-500',
				name: `${tag.title}`,
				type: 'code',
				value: 'Códigos',
			};
		}
		if (tag.id.includes('periodic')) {
			let title = tag?.title || '';
			const tagSplited = String(tag?.id).split('-');
			const idSplited = String(tagSplited?.[1] || '').split('_');
			const periodicId = idSplited?.[0] || '';
			const start_number = idSplited?.[2] || '';
			const final_number = tagSplited?.[2] || '';
			if (periodicId && start_number && final_number && periodics?.length) {
				const p = periodics.find((v) => String(v.numId).replace('P', '') === periodicId);
				title = p?.acronym ? `${p?.acronym} - De ${start_number} a ${final_number}` : title;
			}

			return {
				...tag,
				color: 'bg-gray-600',
				name: `Revistas: ${title}`,
				type: 'periodic',
				value: 'Revistas',
			};
		}

		return {
			...tag,
			color: 'bg-gray-600',
			name: `${tag.title}`,
			type: '',
			value: '',
		};
	});

	return tags_with_color;
}
