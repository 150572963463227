import { Modal, Tabs } from 'antd';
import { FC } from 'react';
import { Client } from '../../../../../lib/model/Client';
import { ContractedContents } from '../../../contracts/components/ContractedContents';
import { ContractDatatable } from '../../components/ContractDataTable';

const { TabPane } = Tabs;

interface IProps {
	visible: boolean;
	close: () => void;
	partner: Client;
}

export const ContractDatatableModal: FC<IProps> = ({ close, visible, partner }) => {
	return (
		<Modal
			{...{
				title: `Contratos de ${partner.name}`,
				centered: true,
				visible,
				width: 800,
				closable: true,
				destroyOnClose: true,
				onCancel: close,
				footer: null,
			}}
		>
			<Tabs type='card'>
				<TabPane tab='Contratos' key={partner.id}>
					<ContractDatatable {...{ partner }} />
				</TabPane>

				<TabPane tab='Conteudos Contratados' key='contents'>
					<ContractedContents {...{ client_id: partner.id }} />
				</TabPane>
			</Tabs>
		</Modal>
	);
};
