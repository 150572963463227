import React, { useEffect, useState } from 'react';
import { useQueryParams } from '../../hooks';
import { Search } from '../Search';
import { SearchableQueryDto } from './model/SearchableQueryDto';

// TODO - Adicionar 'prefix' no texto
// ex: kwrd_direito

interface IProps {
	on_search: (text: string) => any;
}

export const SearchableSearchInputForDigital: React.FC<IProps> = ({ on_search }) => {
	const query_params: SearchableQueryDto = useQueryParams();

	const [text, set_text] = useState<string>(query_params.text || '');

	useEffect(() => {
		if ((query_params?.text || '') !== text) {
			set_text(query_params.text || '');
		}
	}, [query_params?.text]);

	return (
		<Search
			{...{
				onSearch: on_search,
				onChange: (event) => set_text(event.target.value),
				onClear: () => on_search(''),
				value: text,
				placeholder: 'Informe o titulo desejado',
			}}
		/>
	);
};
