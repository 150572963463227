import { FC } from 'react';
import { Tag } from '../../../../../lib/model/Tag';
import { DeleteOutlined } from '@ant-design/icons';

interface IProps {
	items: Tag[];
	remove_tag: (item: Tag) => void;
	get_tag_type_title: (tag: Tag) => string;
}

export const SelectedTags: FC<IProps> = ({ items, remove_tag, get_tag_type_title }) => {
	return (
		<div className='mt-4 w-full'>
			<Title {...{ title: 'Itens Selecionados' }} />
			<Items {...{ items, remove_tag, get_tag_type_title }} />
		</div>
	);
};

interface ITitle {
	title: string;
}

const Title: FC<ITitle> = ({ title }) => {
	return <h1 className='font-semibold mb-2'>{title}</h1>;
};

const Items: FC<IProps> = ({ items, remove_tag, get_tag_type_title }) => (
	<table className='table-layout: auto;'>
		{items.map((content: Tag) => {
			if (
				content.id.includes('periodic') ||
				content.id.includes('book') ||
				content.id.includes('video') ||
				content.id.includes('news') ||
				content.id.includes('code')
			) {
				return (
					// <li
					// 	className='mx-0.5 py-1 px-2 mb-3 rounded text-black cursor-pointer hover:bg-opacity-70'
					// 	onClick={() => remove_tag(content)}
					// >
					// 	{get_tag_type_title(content)}
					// </li>
					<tbody className='ant-table-tbody'>
						<tr data-row-key='1' className='ant-table-row ant-table-row-level-0 '>
							<td className='ant-table-cell'>{get_tag_type_title(content)}</td>
							<td>
								<DeleteOutlined style={{ color: '#ff0000' }} onClick={() => remove_tag(content)} />
							</td>
						</tr>
					</tbody>
				);
			}
			// if (content.id.includes('video')) {
			// 	return (
			// 		<li
			// 			className='mx-0.5 py-1 px-2 mb-3 rounded text-black cursor-pointer hover:bg-opacity-70'
			// 			onClick={() => remove_tag(content)}
			// 		>
			// 			{get_tag_type_title(content)}
			// 		</li>
			// 	);
			// }
			// if (content.id.includes('news')) {
			// 	return (
			// 		<li
			// 			className='mx-0.5 py-1 px-2 mb-3 rounded text-black cursor-pointer hover:bg-opacity-70'
			// 			onClick={() => remove_tag(content)}
			// 		>
			// 			{get_tag_type_title(content)}
			// 		</li>
			// 	);
			// }
			// if (content.id.includes('code')) {
			// 	return (
			// 		<li
			// 			className='mx-0.5 py-1 px-2 mb-3 rounded text-black cursor-pointer hover:bg-opacity-70'
			// 			onClick={() => remove_tag(content)}
			// 		>
			// 			{get_tag_type_title(content)}
			// 		</li>
			// 	);
			// }

			// return (
			// 	<li
			// 		className='mx-0.5 py-1 px-2 mb-3 rounded text-black cursor-pointer  hover:bg-opacity-70'
			// 		onClick={() => remove_tag(content)}
			// 	>
			// 		{get_tag_type_title(content)}
			// 	</li>
			// );
		})}
	</table>
);

// const Items: FC<IProps> = ({ items, remove_tag, get_tag_type_title }) => (
// 	<ul className='flex flex-wrap'>
// 		{items.map((content: Tag) => {
// 			if (content.id.includes('book')) {
// 				return (
// 					<li
// 						className='mx-0.5 py-1 px-2 mb-3 rounded text-white cursor-pointer bg-green-700 hover:bg-opacity-70'
// 						onClick={() => remove_tag(content)}
// 					>
// 						{get_tag_type_title(content)}
// 					</li>
// 				);
// 			}
// 			if (content.id.includes('video')) {
// 				return (
// 					<li
// 						className='mx-0.5 py-1 px-2 mb-3 rounded text-white cursor-pointer bg-blue-600 hover:bg-opacity-70'
// 						onClick={() => remove_tag(content)}
// 					>
// 						{get_tag_type_title(content)}
// 					</li>
// 				);
// 			}
// 			if (content.id.includes('news')) {
// 				return (
// 					<li
// 						className='mx-0.5 py-1 px-2 mb-3 rounded text-white cursor-pointer bg-yellow-500 hover:bg-opacity-70'
// 						onClick={() => remove_tag(content)}
// 					>
// 						{get_tag_type_title(content)}
// 					</li>
// 				);
// 			}
// 			if (content.id.includes('code')) {
// 				return (
// 					<li
// 						className='mx-0.5 py-1 px-2 mb-3 rounded text-white cursor-pointer bg-indigo-500 hover:bg-opacity-70'
// 						onClick={() => remove_tag(content)}
// 					>
// 						{get_tag_type_title(content)}
// 					</li>
// 				);
// 			}

// 			return (
// 				<li
// 					className='mx-0.5 py-1 px-2 mb-3 rounded text-white cursor-pointer bg-gray-500 hover:bg-opacity-70'
// 					onClick={() => remove_tag(content)}
// 				>
// 					{get_tag_type_title(content)}
// 				</li>
// 			);
// 		})}

// 	</ul>
// );
