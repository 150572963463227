import { useEffect, useState } from 'react';
import { useRequest, useRequestFeedback } from '../../../../lib/hooks';
import { request } from '../../../../lib/utils/request';
import { ClientConfigDto } from '../dtos/ClientConfigDto';
import { CreateClientConfigDto } from '../dtos/CreateClientConfigDto';
import { UpdateClientConfigDto } from '../dtos/UpdateClientConfigDto';

const BASE_URL = process.env.REACT_APP_PLAT_CLIENT_CONFIG_API;

interface ClientConfig extends ClientConfigDto {
	refresh_date: number;
}

type UpdateClientSsoTokenKey = {
	key: string;
};

export function useClientConfigApi(id?: string) {
	const [client, set_client] = useState<ClientConfig>();

	const insert = useCreate();
	const client_config = useGet(`${id}`);
	const update = useUpdate(`${id}`, client_config.get);
	const token_key = useTokenKey(id || '');
	const get_last_id = useGetLastID();

	useEffect(() => {
		if (id && id !== 'novo') {
			client_config.get();
		}
	}, [id]);

	useEffect(() => {
		if (client_config.result) {
			set_client(client_config.result);
		}
	}, [client_config.result]);

	return {
		client,
		loading: client_config.loading || update.loading || insert.loading,
		insert,
		update,
		token_key,
		get_last_id,
	};
}

function useGet(id: string) {
	console.log(`${BASE_URL}/${id}`);
	const [result, loading, error, get] = useRequest(() =>
		request()
			.get<ClientConfig>(`${BASE_URL}/${id}`)
			.then((r) => ({ ...r, refresh_date: Date.now() }))
	);

	useRequestFeedback(null, error);

	return {
		result,
		error,
		loading,
		get,
	};
}

function useCreate() {
	const [result, loading, error, send] = useRequest(
		(dto: CreateClientConfigDto) => request().post(`${BASE_URL}`, dto),
		true
	);

	return {
		result,
		error,
		loading,
		send: (dto: CreateClientConfigDto) => send(dto),
	};
}

function useUpdate(id: string, cb: () => any) {
	const req = (dto: UpdateClientConfigDto) => {
		return request()
			.put(`${BASE_URL}/${id}`, dto)
			.then((r) => {
				cb();
				return r;
			});
	};
	const [result, loading, error, send] = useRequest(req, true);

	return {
		result,
		error,
		loading,
		send: (dto: UpdateClientConfigDto) => send(dto),
	};
}

function useTokenKey(id: string) {
	const get_req = () => {
		return request()
			.get(`${BASE_URL}/${id}/sso-token-key`)
			.catch((error) => {
				if (error?.response?.status === 404) {
					return '';
				}

				throw error;
			});
	};

	const [result, loading, error, get] = useRequest(get_req);

	const update_req = (dto: UpdateClientSsoTokenKey) => {
		return request()
			.put(`${BASE_URL}/${id}/sso-token-key`, dto)
			.then((r) => {
				get();
				return r;
			});
	};

	const [, , , update] = useRequest(update_req, true);

	return {
		update: (key: string) => update({ key } as UpdateClientSsoTokenKey),
		get_req: {
			result,
			loading,
			error,
			get,
		},
	};
}

function useGetLastID() {
	const [result, loading, error, do_get] = useRequest<{ last_id: string | number }>(
		() =>
			new Promise((resolve, reject) => {
				request()
					.get(`${BASE_URL}/last-client-id`)
					.then((res) => setTimeout(() => resolve(res as { last_id: string | number }), 1500))
					.catch(reject);
			})
	);

	return {
		get: () => do_get(),
		result,
		error,
		loading,
	};
}
