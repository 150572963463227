import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProtectedContent } from '../../../lib/features/auth/components/ProtectedContent';
import { Role } from '../../../lib/features/auth/model/Role';
import { AddContractPage } from './client_contracts/page/AddContractPage';
import { UpdateContractPage } from './client_contracts/page/UpdateContractPage';

import { AddCopyrightConfigPage } from './pages/AddCopyrightConfigPage';
import { UpdateCopyrightConfigPage } from './pages/UpdateCopyrightConfigPage';
import { CopyrightListPage } from './pages/CopyrightListPage';

export const PlatformCopyrightRoutes: FC = () => (
	<ProtectedContent {...{ roles: [Role.Admin, Role.PlatformAdmin, Role.PlatformCopyfight] }}>
		<Routes>
			<Route {...{ path: ':id/contratos/novo', element: <AddContractPage /> }} />
			<Route {...{ path: ':id/contratos/:c_number', element: <UpdateContractPage /> }} />
			<Route {...{ path: 'novo', element: <AddCopyrightConfigPage /> }} />
			<Route {...{ path: ':id', element: <UpdateCopyrightConfigPage /> }} />
			<Route {...{ path: '/', element: <CopyrightListPage /> }} />
		</Routes>
	</ProtectedContent>
);
