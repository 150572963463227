import { Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateInput } from '../../../../../../lib/components/DateInput';
import { FormButtons } from '../../../../../../lib/components/Form/FormButtons';
import { FormContainer } from '../../../../../../lib/components/Form/FormContainer';
import { FormField } from '../../../../../../lib/components/Form/FormField';
import { useRequestFeedback } from '../../../../../../lib/hooks';
import { Client } from '../../../../../../lib/model/Client';
import { Contract } from '../../../../../../lib/model/Contract';
import { InsertContractDto } from '../../dtos/InsertContractDto';
import { UpdateContractDto } from '../../dtos/UpdateContractDto';
import { useContractManagementApi } from '../../hooks/useContractManagementApi';
import { SelectContractTags } from '../Tags/SelectContractTags';
import { TagTypeWithColor, tag_types } from '../Tags/tag_type';
import { useContractForm } from './useContractForm';

interface IProps {
	partner?: Client;
	contract?: Contract;
}

export const ContractForm: FC<IProps> = ({ partner, contract }) => {
	const navigate = useNavigate();

	const { form_state, show_errors, change_field_value, fill_with_data } = useContractForm();
	const { create, update } = useContractManagementApi();

	const [tag_type, set_tag_type] = useState<TagTypeWithColor>(tag_types[0]);
	const [show_select_tag, set_show_select_tag] = useState(false);

	useEffect(() => {
		fill_with_data({
			c_number: contract?.c_number || '',
			comments: contract?.comments || '',
			end_at: contract?.end_at || Date.now(),
			started_at: contract?.started_at || Date.now(),
			tags: contract?.tags || [],
		});
	}, [contract]);

	function on_insert() {
		show_errors();

		if (!form_state.valid || !partner?.name) {
			return;
		}

		const dto: InsertContractDto = {
			c_number: form_state.value.c_number,
			client_name: partner.name,
			comments: form_state.value.comments,
			started_at: form_state.value.started_at,
			end_at: form_state.value.end_at,
			tags: form_state.value.tags,
		};

		create.do_create(dto);
	}

	function on_update() {
		show_errors();

		if (!form_state.valid || !partner?.name) {
			return;
		}

		const dto: UpdateContractDto = {
			client_name: partner?.name,
			comments: form_state.value.comments,
			started_at: form_state.value.started_at,
			end_at: form_state.value.end_at,
			tags: form_state.value.tags,
		};

		update.do_update(dto);
		localStorage.clear();
	}

	useRequestFeedback(create.result, create.error);
	useRequestFeedback(update.updated, update.error);

	if (!!create.result || !!update.updated) {
		localStorage.clear();
		// navigate('/plataforma/contratos');
	}

	function convert_date_string_to_timestamp(date: string) {
		return Date.parse(date) + 90000000;
	}

	return (
		<FormContainer>
			<div className='w-1/2'>
				<FormField
					{...{
						label: 'Nome do Parceiro',
						name: 'client_name',
						form_state,
					}}
				>
					<Input value={partner?.name || contract?.client_name} disabled />
				</FormField>
				<FormField
					{...{
						label: 'Número da Liberação',
						name: 'c_number',
						form_state,
					}}
				>
					<Input
						value={form_state.value.c_number}
						onChange={(event) => change_field_value('c_number', event.target.value)}
						disabled={!!contract}
					/>
				</FormField>
				<div className='flex flex-row justify-between'>
					<FormField
						{...{
							label: 'Data de Início',
							name: 'started_at',
							form_state,
						}}
					>
						<DateInput
							{...{
								date: new Date(form_state.value.started_at),
								on_changed: (date) =>
									change_field_value('started_at', convert_date_string_to_timestamp(date)),
							}}
						/>
					</FormField>
					<FormField
						{...{
							label: 'Data de Término',
							name: 'end_at',
							form_state,
						}}
					>
						<DateInput
							{...{
								date: new Date(form_state.value.end_at),
								on_changed: (date) =>
									change_field_value('end_at', convert_date_string_to_timestamp(date)),
							}}
						/>
					</FormField>
				</div>

				<SelectContractTags
					{...{
						tag_type,
						on_change: (tags) => {
							change_field_value('tags', tags);
						},
						close: () => set_show_select_tag(false),
						on_select_tag_type: set_tag_type,
						show_content_list: () => set_show_select_tag(true),
						tags: form_state.value.tags,
						visible: show_select_tag,
						isBook: true,
						contents: [],
					}}
				/>

				<FormButtons
					{...{
						disabled: !form_state.valid,
						go_back_on_cancel: true,
						on_add_button_click: contract ? undefined : () => on_insert(),
						on_update_button_click: contract ? () => on_update() : undefined,
					}}
				/>
			</div>
		</FormContainer>
	);
};
