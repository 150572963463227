import { Select } from 'antd';
import { FC } from 'react';
import { TypeBooks } from '../../model/TypeBooks';

const Option = Select;

interface IProps {
	value: TypeBooks;
	on_change: (field_name: any, value: TypeBooks) => void;
}

export const SelectTypeBooks: FC<IProps> = ({ value, on_change }) => {
	return (
		<Select
			className=''
			placeholder='Escolha o tipo desejado'
			onChange={(type) => on_change('type', type)}
			style={{ width: '50%' }}
			value={value}
		>
			<Option value={TypeBooks.SELECIONE} key={TypeBooks.SELECIONE}>
				Selecione
			</Option>
			<Option value={TypeBooks.COLECAO} key={TypeBooks.COLECAO}>
				Coleção
			</Option>
			<Option value={TypeBooks.LIVRO_AVULSO} key={TypeBooks.LIVRO_AVULSO}>
				Livro Avulso
			</Option>
		</Select>
	);
};
