import { Modal, Switch } from 'antd';
import { FC, useEffect, useState } from 'react';
import { Tag } from '../../../../../../lib/model/Tag';
import { SelectTags } from '../SelectTags';
import { SelectTagType } from './SelectTagType';
import { TagTypeWithColor } from './tag_type';
import { PeriodicTagsForm } from '../PeriodicTagsForm';
import { NewsTagForm } from '../NewsTagForm';
import { CodeTagForm } from '../CodeTagForm';
import { SelectTypeBooks } from '../SelectTypeBook';
import { TypeBooks } from '../../../model/TypeBooks';

import { Input, Space } from 'antd';
import { SearchableProvider } from '../../../../../../lib/components/SearchableDataTable/SearchableProvider';
import { default_query } from '../../../../../../lib/utils/consts';
import { ListBooks } from '../../../components/forms/ListBooks';
import { ContentDataTable } from '../../../../../store/content_assignment/components/ContentDataTable';
import { ContentInsertModal } from '../../../../../store/content_assignment/components/ContentModal';
import { BookEditionSelect } from '../../../../../../lib/features/book_edition/components/BookEditionSelect';
import { Content } from '../../../../../../lib/model/Content';
import { LooseBookForm } from '../LooseBookForm';
import { forEach } from 'lodash';
import { title } from 'process';
import { PlatformAllTagForm } from '../PlatformAllTagForm';
import { SelectTagsBooksSeries } from '../SelectTagsBooksSeries';
import { SelectTagsVidsSeries } from '../SelectTagsVidsSeries';

interface IProps {
	visible: boolean;
	close: () => void;
	on_change: (tags: Tag[]) => void;
	tag_type: TagTypeWithColor;
	on_select_tag_type: (tag_type: TagTypeWithColor) => void;
	tags: Tag[];
	periodics?: any[];
	isBook: boolean;
	contents: Content[] | [];
}

export const ContentSeriesModal: FC<IProps> = ({
	visible,
	close,
	on_change,
	tag_type,
	on_select_tag_type,
	tags,
	periodics,
	contents,
}) => {
	const [isTypeSelect, setIsTypeSelect] = useState<String>();

	// const { Search } = Input;
	// const onSearch = (value: string) => console.log(value);

	const abreSelectBooks = (tipo: string, valorSelecionado: string) => {
		setIsTypeSelect(valorSelecionado);
	};

	useEffect(() => {
		setIsTypeSelect('Selecione');
	}, []);

	return (
		<Modal
			{...{
				visible,
				closable: true,
				onCancel: close,
				centered: true,
				destroyOnClose: true,
				title: 'Seleção de Conteúdos do Parceiro',
				width: 800,
				footer: null,
			}}
		>
			{tags.find((item) => item.id.includes('platform-all')) ? (
				<PlatformAllTagForm
					{...{
						selected_contents: tags,
						tag_type,
						on_change,
						close,
					}}
				/>
			) : (
				<>
					<PlatformAllTagForm
						{...{
							selected_contents: tags,
							tag_type,
							on_change,
							close,
						}}
					/>
					<SelectTagType {...{ on_select_tag_type }} />

					{tag_type.value && (
						<>
							<h2 className='mt-2 mb-0'>
								{tag_type?.value === 'periodic' ? 'Tags de Revistas' : ''}
								{tag_type?.value === 'book' ? 'Selecione um item' : ''}
								{!['periodic', 'news', 'code', 'book'].includes(tag_type?.value)
									? `Séries de ${tag_type.title}`
									: ''}
							</h2>
							{tag_type?.value === 'periodic' ? (
								<PeriodicTagsForm
									{...{
										selected_contents: tags,
										tag_type,
										on_change,
										close,
									}}
								/>
							) : (
								<></>
							)}

							{tag_type?.value === 'news' ? (
								<NewsTagForm
									{...{
										selected_contents: tags,
										tag_type,
										on_change,
										close,
									}}
								/>
							) : (
								<></>
							)}

							{tag_type?.value === 'code' ? (
								<CodeTagForm
									{...{
										selected_contents: tags,
										tag_type,
										on_change,
										close,
									}}
								/>
							) : (
								<></>
							)}

							{tag_type?.value === 'book' && isTypeSelect === 'Selecione' ? (
								<>
									<SelectTypeBooks {...{ value: TypeBooks.SELECIONE, on_change: abreSelectBooks }} />
								</>
							) : (
								<></>
							)}
							{tag_type?.value === 'book' && isTypeSelect === 'Livro Avulso' ? (
								<>
									<SelectTypeBooks
										{...{ value: TypeBooks.LIVRO_AVULSO, on_change: abreSelectBooks }}
									/>
									<LooseBookForm
										{...{
											selected_contents: tags,
											tag_type,
											on_change,
											close,
											periodics,
											contents,
										}}
									/>
								</>
							) : (
								<></>
							)}
							{tag_type?.value === 'book' && isTypeSelect === 'Coleção' ? (
								<>
									<SelectTypeBooks {...{ value: TypeBooks.COLECAO, on_change: abreSelectBooks }} />
									<SelectTagsBooksSeries
										{...{
											selected_contents: tags,
											tag_type,
											on_change,
											close,
											periodics,
										}}
									/>
								</>
							) : (
								<></>
							)}

							{/* {isBook ? (
						<SelectTags
							{...{
								selected_contents: tags,
								tag_type,
								on_change,
								close,
								periodics,
							}}
						/>
					) : (
						<></>
					)} */}
							{tag_type?.value === 'video' ? (
								<SelectTagsVidsSeries
									{...{
										selected_contents: tags,
										tag_type,
										on_change,
										close,
										periodics,
									}}
								/>
							) : (
								<></>
							)}
							{!['periodic', 'news', 'code', 'book', 'video'].includes(tag_type?.value) ? (
								<SelectTags
									{...{
										selected_contents: tags,
										tag_type,
										on_change,
										close,
										periodics,
									}}
								/>
							) : (
								<></>
							)}
						</>
					)}
				</>
			)}
		</Modal>
	);
};
