import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { PlatformClientsRoutes } from './clients/routes';
import { PlatformClippingRoutes } from './clipping/routes';
import { ContractsListPage } from './contracts/pages/ContractsListPage';
import { PlatformPartnersRoutes } from './partners/routes';
import { PlatformDigitalRoutes } from './digital/routes';
import { PlatformCopyrightRoutes } from './copyright/routes';

export const PlatformRoutes: FC = () => (
	<Routes>
		<Route path='fontes-de-informativos/*' element={<PlatformClippingRoutes />} />
		<Route path='clientes/*' element={<PlatformClientsRoutes />} />
		<Route path='parceiros/*' element={<PlatformPartnersRoutes />} />
		<Route path='digital/*' element={<PlatformDigitalRoutes />} />
		<Route path='direitoautoral/*' element={<PlatformCopyrightRoutes />} />
		<Route path='contratos' element={<ContractsListPage />} />
	</Routes>
);
