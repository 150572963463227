import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { complement, last, prop } from 'ramda';
import React, { useState } from 'react';
import { Content } from '../../../../model/Content';
import { useBookEdition } from '../../hooks/useBookEdition';
import { EditionCover } from '../EditionCover';
import { Book, Edition } from './types';

interface IProps {
	selecteds: Content[];
	book: Book;
	on_change: (selecteds: Content[]) => void;
}

export const BookItem: React.FC<IProps> = ({ book, selecteds, on_change }) => {
	const { get_id_from_tags } = useBookEdition();
	const [open, set_open] = useState(false);
	const cls = open ? ` border pb-3 ` : '';

	const checked_value_component = book.editions.map((edition) => {
		const convert_edition_to_content = (edition: Edition): Content => {
			const [err, id] = get_id_from_tags(edition.tags);

			if (err) {
				return {
					id: '',
					title: '',
					authors: '',
				};
			}

			return {
				id: id + '',
				title: `${edition.title} - Ed. ${edition.number}`,
				authors:
					edition.coordinators.length > 0
						? edition.coordinators.map(prop('title')).join(', ')
						: edition.authors.map(prop('title')).join(', '),
			};
		};

		const content: Content = convert_edition_to_content(edition);

		const checked = is_selected(content, selecteds);

		return [
			checked,
			content,
			<li key={edition.number} className='flex flex-row items-center '>
				<label className='py-3 text-base cursor-pointer w-full hover:bg-blue-100'>
					<input
						type='checkbox'
						checked={checked}
						className='mr-2'
						onChange={() => {
							const selection_result = is_selected(content, selecteds)
								? selecteds.filter(complement(is_equal(content)))
								: [...selecteds, content];

							on_change(selection_result);
						}}
					/>
					{edition.number}ª Edição
				</label>
			</li>,
		];
	});

	const has_edition_selected = checked_value_component.some(([checked]) => checked);

	return (
		<li key={book.title} className={`px-4 cursor-pointer hover:bg-gray-100 ${cls}`}>
			<button className=' flex w-full py-6 items-center px-2' onClick={() => set_open(!open)}>
				<div className=' w-16'>
					<EditionCover
						{...{
							quality: 'low',
							tags: last(book.editions)?.tags,
						}}
					/>
				</div>

				<div className=' flex justify-between w-full ml-3'>
					<span className='font-bold text-base'>{book.title}</span>
					{open ? (
						<UpOutlined style={{ fontSize: 14, marginRight: 3 }} />
					) : (
						<DownOutlined style={{ fontSize: 14, marginRight: 3 }} />
					)}
				</div>
			</button>

			{(has_edition_selected || open) && (
				<ul className='mt-3 ml-10 bg-white'>
					{checked_value_component.map(([, , component]) => component)}
				</ul>
			)}
		</li>
	);
};

const is_equal = (a: Content) => (b: Content) => a.id === b.id;

const is_selected = (content: Content, content_selected: Content[]) =>
	content_selected?.some(is_equal(content));
