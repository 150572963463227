import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { PageLayout } from '../../../../../lib/shell/PageLayout/PageLayout';
import { usePartnerConfigApi } from '../../hooks/usePartnerConfigApi';
import { ContractForm } from '../components/ContractForm/ContractForm';
import { ContractsBreadcrumb } from '../components/ContractsBreadcrumb';

export const AddContractPage: FC = () => {
	const { id: client_id } = useParams();
	const { partner } = usePartnerConfigApi(client_id);

	return (
		<PageLayout
			{...{
				title: `Novo Contrato - ${partner?.name}`,
				breadcrumb: () => <ContractsBreadcrumb {...{ client_id }} />,
				browser_back: true,
			}}
		>
			<ContractForm {...{ partner }} />
		</PageLayout>
	);
};
