import { FC, useEffect, useState } from 'react';
import { CloseButton } from '../../../../../lib/components/Buttons/CloseButton';
import { Loader } from '../../../../../lib/components/Loader';
import { TagDto } from '../../../../../lib/dtos/TagDto';
import { Tag } from '../../../../../lib/model/Tag';
import { request } from '../../../../../lib/utils/request';
import { ListTags } from './ListTags';
import { SelectedTags } from './SelectedTags';
import { TagTypeWithColor } from './Tags/tag_type';
import { ListTagsBooksSeries } from './ListTagsBooksSeries';

const BASE_URL = process.env.REACT_APP_CLIENT_CONTRACT_MANAGEMENT_API;

interface IProps {
	selected_contents: Tag[];
	tag_type: TagTypeWithColor;
	on_change: (tags: Tag[]) => void;
	close: () => void;
	periodics?: any[];
}

export const SelectTagsVidsSeries: FC<IProps> = ({
	on_change,
	tag_type,
	selected_contents,
	close,
	periodics,
}) => {
	const [selected_tags, set_selected_tags] = useState(selected_contents);
	const [is_loading, set_is_loading] = useState(false);

	function insert_tag_with_color(tag: Tag) {
		set_selected_tags([...selected_tags, tag]);
	}

	function remove_tag(tag: Tag) {
		const new_contents = selected_tags.filter((content) => content !== tag);

		set_selected_tags(new_contents);
	}

	function select_tags() {
		on_change(selected_tags);
		close();
	}

	const get_tag_type_title = (tag: Tag): string => {
		if (tag?.id?.includes('book_')) {
			return `Livro Avulso: ${tag.title}`;
		}
		if (tag?.id?.includes('book')) {
			return tag?.id?.includes('legislacao-comentada') ? `${tag.title}` : `Livros: ${tag.title}`;
		}
		if (tag?.id?.includes('video')) {
			return `Vídeos: ${tag.title}`;
		}
		if (tag?.id?.includes('periodic')) {
			let title = tag?.title || '';
			const tagSplited = String(tag?.id).split('-');
			const idSplited = String(tagSplited?.[1] || '').split('_');
			const periodicId = idSplited?.[0] || '';
			const start_number = idSplited?.[2] || '';
			const final_number = tagSplited?.[2] || '';
			const periodicsArray = periodics || [];
			if (periodicId && start_number && final_number && periodicsArray?.length) {
				const p = periodicsArray.find((v) => String(v.numId).replace('P', '') === periodicId);
				title = p?.acronym ? `${p?.acronym} - De ${start_number} a ${final_number}` : title;
			}

			return `Revistas: ${title}`;
		}
		return `${tag.title}`;
	};

	useEffect(() => {
		on_change(selected_tags);
	}, [selected_tags]);

	return (
		<section className='flex flex-col w-full my-1.5'>
			<div className='flex flex-row justify-between'>
				<Loader
					{...{
						request: () => {
							set_is_loading(true);
							return request().get<TagDto[]>(`${BASE_URL}/tags/${tag_type.value}`);
						},
						reload_when_changes: [tag_type.value],
						renderChildren: (result: Tag[]) => {
							set_is_loading(false);
							return (
								<ListTagsBooksSeries
									{...{
										insert_tag_with_color,
										items: result,
										selected_tags: selected_tags,
										tag_type,
										get_tag_type_title,
									}}
								/>
							);
						},
					}}
				/>
			</div>
			{selected_tags.length !== 0 && !is_loading && (
				<SelectedTags {...{ items: selected_tags, remove_tag, get_tag_type_title }} />
			)}
			<div className='flex justify-end mt-8'>
				<CloseButton disabled={selected_tags.length === 0} onClick={select_tags} />
			</div>
		</section>
	);
};
